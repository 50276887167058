import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Appearance, Linking, Pressable } from 'react-native';
import { useFonts } from 'expo-font';
import { initializeApp } from "firebase/app";
import { useEffect, useState } from 'react';

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCE1HrYGMSiHOH1wsUe2d29AYTv0QU3HA8",
  authDomain: "koshka-se.firebaseapp.com",
  projectId: "koshka-se",
  storageBucket: "koshka-se.appspot.com",
  messagingSenderId: "947077042298",
  appId: "1:947077042298:web:7da5408a35bf996ea0ebc3"
};
const app = initializeApp(firebaseConfig);

if (typeof document !== 'undefined') { document.title = "Koshka.se" };


export default function App() {

  const [darkMode, setDarkMode] = useState(Appearance.getColorScheme() === 'dark');

  useFonts({
    'koshkaFont': require('./assets/fonts/Lato.ttf')
  });

  useEffect(() => {
    Appearance.addChangeListener(({ colorScheme }) => {
      setDarkMode(colorScheme === 'dark');
    });
  }, []);

  return (
    <View style={[styles.container, darkMode && { backgroundColor: '#000000' }]} >
      <Pressable onPress={() => { Linking.openURL('https://app.koshka.se/') }}>
        <View style={styles.mainView} >
          <Text selectable={false} style={styles.mainText}>K O S H K A</Text>
        </View>
      </Pressable>
      <Text selectable={false} style={styles.clickMeText} >Click me for Koshka App!</Text>

      <StatusBar style="auto" />
    </View >
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainView: {
    height: 250,
    width: 250,
    borderRadius: 40,
    backgroundColor: '#FF3C7B',
    shadowOpacity: 0.2,
    shadowRadius: 20,
    shadowColor: '#52006A'
  },
  mainText: {
    textAlign: 'center',
    paddingTop: 60,
    fontFamily: 'koshkaFont',
    fontSize: 48,
    color: 'white'
  },
  clickMeText: {
    textAlign: 'center',
    paddingTop: 60,
    fontFamily: 'koshkaFont',
    fontSize: 38,
    color: '#FF3C7B'
  }
});
